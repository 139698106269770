class Agenda {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;

  }

  init() {

    let _self = this;
    // Resize Titles if they are too long
    this.titles = $('.o-events-list .o-event__title');
    if(this.titles.length) {

      $(window).on('resize', _.debounce(this._resize.bind(this), 400));
      this._resize();
    }

    let landingAgenda = $('.t-agenda__landing');
    if(landingAgenda.length) {

      let landingAgendaBG = landingAgenda.find('.o-landing__decoration svg');
      // animate header bg after a delay
      setTimeout(() => {
        if(_self.debug) console.log('Agenda .t-agenda__landing .o-landing__decoration svg ??', $('.t-agenda__landing .o-landing__decoration svg'));
        landingAgendaBG.get(0).classList.add('active');
      }, 500);

      // rewind animation when mouse is on header and play it back on leave
      landingAgenda.on('mouseenter', function(e){
        if(_self.debug) console.log('Agenda .t-agenda__landing mouseenter ??', e, landingAgendaBG.get(0));
        landingAgendaBG.get(0).classList.remove('active');
      }).on('mouseleave', function(e) {
        landingAgendaBG.get(0).classList.add('active');
      });
      
    }

  }

  resizeTitles(){

    let _self = this;
    let targetNumLines = window.innerWidth < 768 ? 4 : 2;
    let step = 1;

    this.titles.each(function(i,title){
      //reset title font sizes
      $(title).css('font-size', '');

      let titleLineHeight = parseFloat($(title).css('line-height'));
      let titleFontSize = parseFloat($(title).css('font-size'));
      let titleHeight = $(title).height();
      let targetHeight = Math.ceil(targetNumLines * titleLineHeight);
      if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);

      // //if is greater than target number of lines
      if(titleHeight > targetHeight) {
        // reduce font size until not overflown
        while (titleHeight > targetHeight) {
          titleFontSize = parseFloat($(title).css('font-size'));
          $(title).css('font-size', (titleFontSize - step) + 'px');
          titleLineHeight = parseFloat($(title).css('line-height'));
          targetHeight = Math.ceil(targetNumLines * titleLineHeight);
          titleHeight = $(title).height();
          if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);
        }
      }
    });
  }

  _resize() {

    this.resizeTitles();

  }

}

export { Agenda };
