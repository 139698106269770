class Home {
  constructor() {

      this.debug = window.location.search == "?debug" ? true : false;

  }

  init() {

    let _self = this;

    if(_self.debug) console.log('Home init');
    
    // let homeEventsSlider = $('#home-events-slider');
    // if(homeEventsSlider.length) {
    //   let homeEventsSlider_slides = homeEventsSlider.find('.swiper-slide');
    //   if(homeEventsSlider_slides.length > 1) {
    //
    //     let gallerySwiper = new Swiper(homeEventsSlider.get(0), {
    //       slidesPerView: 1,
    //       direction: 'vertical',
    //       // slidesPerColumn: 2,
    //       spaceBetween: 28,
    //       // slidesPerGroup: 2,
    //       watchOverflow: true,
    //       autoHeight: true,
    //       // spaceBetween: -5,
    //       // effect: 'fade',
    //       // fadeEffect: {
    //       //   crossFade: true
    //       // },
    //       // autoHeight: true,
    //       loop: true,
    //       rewind: false,
    //       loopAdditionnalSlides: 6,
    //       centeredSlides: true,
    //       // loopFillGroupWithBlank: true,
    //       breakpoints: {
    //         768: {
    //           spaceBetween: 24
    //         },
    //         1024: {
    //           // slidesPerView: "auto",
    //           // slidesPerView: 1,
    //           // spaceBetween: -36,
    //           // direction: 'horizontal',
    //           // effect: 'fade'
    //         },
    //         1280: {
    //           spaceBetween: 30
    //         },
    //       //   // 1024: {
    //       //   //   spaceBetween: -10,
    //       //   // },
    //       //   // 1280: {
    //       //   //   spaceBetween: -20,
    //       //   // },
    //       },
    //       navigation: {
    //         nextEl: homeEventsSlider.find('.o-events-slider__nav .btn-arrow-down').get(0),
    //         prevEl: homeEventsSlider.find('.o-events-slider__nav .btn-arrow-up').get(0),
    //       },
    //       // on: {
    //         // 'resize': function () {
    //         //   this.changeDirection(_self.getDirection());
    //         // },
    //         // 'lock': function(){
    //         //   console.log('lock event', this.el);
    //         //   $(this.el).addClass('swiper-pagination-locked');
    //         // },
    //         // 'unlock': function(){
    //         //   console.log('unlock event', this.el);
    //         //   $(this.el).removeClass('swiper-pagination-locked');
    //         // },
    //       // },
    //
    //     });
    //   }
    //
    // }

  }

}

export { Home };
