import { TextLoop } from './text-loop.js';

class EventsSlider {

  constructor() {

    this.breakpoint = 1024;
    // this.isVertical = true;
    // this.direction = 'vertical';
    this.effect = window.innerWidth >= this.breakpoint ? 'fade' : 'slide';
    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    this.currentIndex = 1;
    this.alreadyRunning = false;
    this.currentTimeline = null;
    this.nextTimeline = [];
    this.sliderEvents1Queue = [];

    window.EventsSlider = this;

  }

  init() {

    // this.debug = true;

    let _self = this;

    this.eventsSliderContainer = $('#home-events-slider');

    if(this.eventsSliderContainer.length) {

      if(_self.debug) console.log('[EventsSlider] eventsSliderContainer', this.eventsSliderContainer);

      this.titles = $('.o-events-slider .o-event__title');
      this.textLoopContainer = $('.o-events-slider__text-loop');

      const _textLoop = new TextLoop();
      this.textLoop = _textLoop;
      this.textLoop.init();



      // first initialization
      if(this.effect == 'fade') {
        this.sliderEvents = this.initSwiperDesktop();
      } else {
        this.sliderEvents = this.initSwiperMobile();
      }

      const breakpoints = [this.breakpoint];

      const generateMatches = (breakpoints, cb) => breakpoints.map((breakpoint) => {
        const mql = window.matchMedia(`(min-width: ${breakpoint}px)`); // create a MediaQueryList

        // create the listener and return the handler, so it can be canceled
        return mql.addListener((e) => cb(e, breakpoint));
      });

      const myFunc = (e, breakpoint) => {
        _self.checkSliderVersion(breakpoint);
        if(_self.debug) console.log(`${breakpoint}: ${e.matches}`);
      }

      const listeners = generateMatches(breakpoints, myFunc);

      $(window).on('resize', _.debounce(this._resize.bind(this), 400));
      this._resize();

    }

  }

  checkSliderVersion(breakpoint){

    let _self = this;

    if(window.innerWidth >= breakpoint) {
      if(_self.debug) console.log('[EventsSlider] checkSliderVersion desktop', breakpoint, $(window).width(), this.effect);
      if(this.effect == 'slide') {
        if(_self.debug) console.log('[EventsSlider] checkSliderVersion desktop BREAKPOINT changing slider to fade and triple version');
        this.changeEffect();
      }
    } else {
      if(_self.debug) console.log('[EventsSlider] checkSliderVersion mobile', breakpoint, $(window).width(), this.effect);
      if(this.effect == 'fade') {
        if(_self.debug) console.log('[EventsSlider] checkSliderVersion mobile BREAKPOINT changing slider to vertical and slide');
        this.changeEffect();
      }
    }
  }

  changeEffect() {

    let _self = this;

    this.effect = (this.effect == 'slide') ? 'fade' : 'slide';
    if(_self.debug) console.log('[EventsSlider] changeEffect NEW effect', this.effect);
    if(this.effect == 'fade') {
      //desktop version
      if(_self.debug) console.log('[EventsSlider] enabling desktop version');
      this.sliderEvents.destroy(true, true);
      this.sliderEvents = this.initSwiperDesktop();
    } else {
      //mobile version
      if(_self.debug) console.log('[EventsSlider] enabling mobile version');
      this.sliderEvents.destroy(true, true);
      if('undefined' !== typeof this.sliderEvents2) this.sliderEvents2.destroy(true, true);
      if('undefined' !== typeof this.sliderEvents3) this.sliderEvents3.destroy(true, true);
      this.sliderEvents = this.initSwiperMobile();
      this.sliderMedias.slideTo(slideIndex,0);

    }

  }

  _resize() {

    let _self = this;

    if(window.innerWidth >= this.breakpoint) {
      if(_self.debug) console.log('[EventsSlider] _resize desktop', this.breakpoint, $(window).width(), this.effect);
      if(this.effect == 'slide') {
        if(_self.debug) console.log('[EventsSlider] _resize desktop BREAKPOINT changing slider to fade and triple version');
        this.changeEffect();
      }
    } else {
      if(_self.debug) console.log('[EventsSlider] _resize mobile', this.breakpoint, $(window).width(), this.effect);
      if(this.effect == 'fade') {
        if(_self.debug) console.log('[EventsSlider] _resize mobile BREAKPOINT changing slider to vertical and slide');
        this.changeEffect();
      }
    }

    if(_self.debug) console.log('[EventsSlider] resize?', this);

    this.resizeTitles(false);


  }

  initSwiperDesktop() {

    let _self = this;

    if(_self.debug) console.log('[EventsSlider] initSwiperDesktop');

    // sliders common parameters
    let sliderParams = {
      slidesPerView: 1,
      direction: 'horizontal',
      allowTouchMove: false,
      spaceBetween: 0,
      loop: true,
      // centeredSlides: true,
      // on: {
      //   slideChange: function(swiper){
      //     // if(_self.debug) console.log('[EventsSlider] desktop SLIDE CHANGE', swiper, this);
      //   },
      // },
      effect: _self.effect,
      fadeEffect: {
        crossFade: true
      },
    };

    // delete sliderParams1.fadeEffect;

    // clone slider and shift by one slide in either direction
    $('#home-events-slider-2').remove(); // for safety
    _self.eventsSliderContainer2 = _self.eventsSliderContainer.clone().attr('id', 'home-events-slider-2').appendTo($('.t-home__landing-events'));
    if(_self.debug) console.log('[EventsSlider] initSwiperDesktop eventsSliderContainer2?', _self.eventsSliderContainer2);
    _self.eventsSliderContainer2.find('.swiper-navigation').remove();
    _self.eventsSliderContainer2.find('.swiper-slide').first().appendTo(_self.eventsSliderContainer2.find('.swiper-wrapper'));

    $('#home-events-slider-3').remove(); // for safety
    _self.eventsSliderContainer3 = _self.eventsSliderContainer.clone().attr('id', 'home-events-slider-3').appendTo($('.t-home__landing-events'));
    if(_self.debug) console.log('[EventsSlider] initSwiperDesktop eventsSliderContainer3?', _self.eventsSliderContainer3);
    _self.eventsSliderContainer3.find('.swiper-navigation').remove();
    _self.eventsSliderContainer3.find('.swiper-slide').last().prependTo(_self.eventsSliderContainer3.find('.swiper-wrapper'));

    let sliderParamsClones = Object.assign({}, sliderParams);
    // init clone sliders
    _self.sliderEvents2 = new Swiper(_self.eventsSliderContainer2.get(0), sliderParamsClones);
    _self.sliderEvents3 = new Swiper(_self.eventsSliderContainer3.get(0), sliderParamsClones);
    //add navigation for main slider
    let sliderParams1 = Object.assign({}, sliderParams);
    // sliderParams1.navigation = {
    //   nextEl: _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-down').get(0),
    //   prevEl: _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-up').get(0),
    // };
    _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-down').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      if(_self.debug) console.log('[EventsSlider] CLICK DOWN alreadyRunning', _self.alreadyRunning, 'sliderEvents1Queue.length', _self.sliderEvents1Queue.length);
      if(_self.alreadyRunning || _self.sliderEvents1Queue.length > 0) _self.sliderEvents1Queue.push('DOWN');
      else sliderEvents1.slideNext();
      if(_self.debug) console.log('[EventsSlider] CLICK DOWN _self.sliderEvents1Queue', _self.sliderEvents1Queue);
    });
    _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-up').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      if(_self.alreadyRunning || _self.sliderEvents1Queue.length > 0) _self.sliderEvents1Queue.push('UP');
      else sliderEvents1.slidePrev();
      if(_self.debug) console.log('[EventsSlider] CLICK UP _self.sliderEvents1Queue', _self.sliderEvents1Queue);
    });


    // add controller params to main slider
    sliderParams1.controller = {
      control: [_self.sliderEvents2, _self.sliderEvents3]
    };
    sliderParams1.on = {
      slideChange: function(swiper){
        //update text-loop on slide change
        if(_self.debug) console.log('[EventsSlider] desktop SLIDE CHANGE', swiper, this, $(this.slides).eq(this.activeIndex).find('.o-event__title').text());
        _self.textLoopContainer.find('.text-loop__content').text( $(this.slides).eq(this.activeIndex).find('.o-event__title').text() );
        _self.textLoopContainer.removeClass('hidden');
        _self.textLoop.fillWithClones('.text-loop__container', '.text-loop__content');
        _self.textLoopContainer.addClass('animated');
      }
    };
    sliderParams1.autoplay = {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    };
    // sliderParams1.fadeEffect = {
    //   crossFade: true
    // };
    // init main slider
    var sliderEvents1 = new Swiper(_self.eventsSliderContainer.get(0), sliderParams1);

    var sliderEvents1ScrollTrigger = ScrollTrigger.create({
      id: 'sliderEvents1-ST',
      trigger: sliderEvents1.$el,
      start: "top bottom",
      end: "bottom top",
      onToggle: self => {
        if(_self.debug) console.log("toggled, sliderEvents1-ST isActive:", self.isActive);
        //is it in view ?
        if (self.isActive) {
          sliderEvents1.autoplay.start();
        } else {
          sliderEvents1.autoplay.stop();
        }
      },
      markers: _self.debug,
    });

    let transitionDuration = 0.8;
    let transitionDelay = 0.15;
    let minScale = 0.8;
    let timelineStart = gsap.timeline({
      ease: "none",
      // ease: "power2.out",
      paused: true
    });
    $(_self.eventsSliderContainer).find('.swiper-slide-active .o-event__thumbnail').css('opacity', 1);
    timelineStart.to($(_self.eventsSliderContainer).find('.swiper-slide-active .o-event__thumbnail'), {scale: 1, duration: transitionDuration});
    timelineStart.to($(_self.eventsSliderContainer2).find('.swiper-slide-active .o-event__thumbnail'), {scale: 1, duration: transitionDuration}, "<");
    timelineStart.to($(_self.eventsSliderContainer3).find('.swiper-slide-active .o-event__thumbnail'), {scale: 1, duration: transitionDuration}, "<");
    timelineStart.play();
    // eventsSliderContainer1.on('beforeTransitionStart', function(){
    //   if(_self.debug) console.log('[EventsSlider] beforeTransitionStart activeIndex', this.previousIndex, this.activeIndex);
    // });
    sliderEvents1.on('activeIndexChange', function(){
      if(_self.debug) console.log('[EventsSlider] activeIndexChange activeIndex', this.previousIndex, this.activeIndex, this, this.slides.length);
      if(this.previousIndex > this.activeIndex && this.previousIndex != this.slides.length-1) {

        if(_self.currentTimeline != null) {
          if(_self.debug) console.log('[EventsSlider] activeIndexChange DOWN currentTimeline progress', _self.currentTimeline.progress());
          _self.alreadyRunning = true;
          _self.currentTimeline.timeScale(2.5);
        }
        let timelineUp = gsap.timeline({
          // ease: "none",
          ease: "power2.inOut",
          paused: true,
          onStart: function(){
            _self.alreadyRunning = true;
          },
          onComplete: function(){
            if(_self.debug) console.log('[EventsSlider] timelineUp complete', this, _self.sliderEvents1Queue);
            _self.currentTimeline = null;
            _self.alreadyRunning = false;
            sliderEvents1.allowTouchMove = true;
            if(_self.sliderEvents1Queue.length > 0) {
              let nextQueueItem = _self.sliderEvents1Queue.shift();
              if(_self.debug) console.log('[EventsSlider] timelineUp complete QUEUE nextQueueItem', nextQueueItem);
              if(nextQueueItem == 'DOWN') sliderEvents1.slideNext();
              if(nextQueueItem == 'UP') sliderEvents1.slidePrev();
            }
          }
        });
        let topImage = $(_self.sliderEvents3.slides[this.previousIndex]).find('.o-event__thumbnail').css('opacity', 1);
        let topImageNew = $(_self.sliderEvents3.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldTopImageNew = $(_self.sliderEvents3.slides).find('.o-event__thumbnail.oldTopImageNew');
        let centerImage = $(sliderEvents1.slides[this.previousIndex]).find('.o-event__thumbnail');
        let centerImageNew = $(sliderEvents1.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldCenterImageNew = $(sliderEvents1.slides).find('.o-event__thumbnail.oldCenterImageNew');
        let bottomImage = $(_self.sliderEvents2.slides[this.previousIndex]).find('.o-event__thumbnail').css('opacity', 1);
        let bottomImageNew = $(_self.sliderEvents2.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldBottomImageNew = $(_self.sliderEvents2.slides).find('.o-event__thumbnail.oldBottomImageNew');

        // clean up classes
        topImage.add(topImageNew).add(oldTopImageNew).add(centerImage).add(centerImageNew).add(oldCenterImageNew).add(bottomImage).add(bottomImageNew).add(oldBottomImageNew).removeClass('topImage topImageNew centerImage centerImageNew bottomImage bottomImageNew');

        // if(_self.debug) console.log('[EventsSlider] activeIndexChange UP', this.previousIndex, this.activeIndex,
        // 'TOP IMAGE?', topImage.addClass('topImage'),
        // 'TOP IMAGE NEW?', topImageNew.addClass('topImageNew'),
        // 'OLD TOP IMAGE NEW?', oldTopImageNew,
        // 'CENTER IMAGE?', centerImage.addClass('centerImage'),
        // 'CENTER IMAGE NEW?', centerImageNew.addClass('centerImageNew'),
        // 'OLD CENTER IMAGE NEW?', oldCenterImageNew,
        // 'BOTTOM IMAGE?', bottomImage.addClass('bottomImage'),
        // 'BOTTOM IMAGE NEW?', bottomImageNew.addClass('bottomImageNew'));
        // 'OLD BOTTOM IMAGE NEW?', oldBottomImageNew,

        // add labels
        timelineUp.addLabel('timelineStart', 0);
        timelineUp.addLabel('timelineHalf', transitionDuration/2);
        timelineUp.addLabel('timelineEnd', transitionDuration);
        // TODO: animate top image from top to center (then show real new center image)
        timelineUp.set(topImage, {opacity: 1, scale: 1, y: '0vh'}, 'timelineStart'); // show top image and place at top of screen
        timelineUp.to(topImage, {y: '50vh', duration: transitionDuration, delay: transitionDelay}, 'timelineStart'); // animate top image to center
        timelineUp.to(topImage, {scale: minScale, duration: transitionDuration/2, delay: transitionDelay}, 'timelineStart'); // scale top image to half size
        timelineUp.to(topImage, {scale: 1, duration: transitionDuration/2, delay: transitionDelay, onComplete: function(){
          centerImageNew.addClass('oldCenterImageNew');
          topImageNew.addClass('oldTopImageNew');
          bottomImageNew.addClass('oldBottomImageNew');
        }}, 'timelineHalf'); // scale top image to full size
        timelineUp.set(topImage, {opacity: 0, scale: 1, y: '0vh', delay: transitionDelay}, 'timelineEnd'); // hide top image and place at top of screen
        timelineUp.set(centerImageNew, {opacity: 1, scale: 1, delay: transitionDelay}, 'timelineEnd'); // show real new center image (main slider)

        // TODO: animate NEW top image from above the sky to top of screen
        timelineUp.set(topImageNew, {opacity: 1, scale: 1, y: '-50vh'}, 'timelineStart'); // show new top image and place above the sky
        if(oldTopImageNew.length && !oldTopImageNew.is(topImage)) timelineUp.set(oldTopImageNew, {opacity: 0, scale: 1}, 'timelineStart'); //hide old (new) top image if present
        timelineUp.to(topImageNew, {y: '0vh', duration: transitionDuration}, 'timelineStart'); // animate new top image to top of screen
        timelineUp.to(topImageNew, {scale: minScale, duration: transitionDuration/2}, 'timelineStart'); // scale new top image to half size
        timelineUp.to(topImageNew, {scale: 1, duration: transitionDuration/2, onStart: function(){
          oldTopImageNew.removeClass('oldTopImageNew');
        }}, 'timelineHalf'); // scale new top image to full size

        // TODO: animate bottom image to hell! (below bottom of screen)
        timelineUp.set(bottomImage, {opacity: 1, scale: 1, y: '0vh'}, 'timelineStart'); // show bottom image and place at bottom
        if(oldBottomImageNew.length && !oldBottomImageNew.is(bottomImage)) timelineUp.set(oldBottomImageNew, {opacity: 0, scale: 1}, 'timelineStart'); //hide old (new) bottom image if present
        timelineUp.to(bottomImage, {y: '50vh', duration: transitionDuration, delay: transitionDelay}, 'timelineStart'); // animate bottom image to hell!
        timelineUp.to(bottomImage, {scale: minScale, duration: transitionDuration/2, delay: transitionDelay}, 'timelineStart'); // scale bottom image to half size
        timelineUp.to(bottomImage, {scale: 1, duration: transitionDuration/2, delay: transitionDelay, onStart: function(){
          if(oldBottomImageNew.length) oldBottomImageNew.removeClass('oldBottomImageNew');
        }}, 'timelineHalf'); // scale bottom image to full size

        // TODO: animate NEW bottom image from center to bottom
        timelineUp.set(bottomImageNew, {opacity:1, scale: 1, y: '-50vh'}, 'timelineStart'); // show new bottom image and place it at center
        timelineUp.set(centerImage, {opacity: 0, scale: 1}, 'timelineStart'); // hide real center image (main slider)
        if(oldCenterImageNew.length && !oldCenterImageNew.is(bottomImageNew)) timelineUp.set(oldCenterImageNew, {opacity: 0, scale: 1}, 'timelineStart'); // hide old (new) center image if present
        timelineUp.to(bottomImageNew, {y: '0vh', duration: transitionDuration}, 'timelineStart'); // animate new bottom image to bottom
        timelineUp.to(bottomImageNew, {scale: minScale, duration: transitionDuration/2}, 'timelineStart'); // scale new bottom image to half size
        timelineUp.to(bottomImageNew, {scale: 1, duration: transitionDuration/2, onStart: function(){
          oldCenterImageNew.removeClass('oldCenterImageNew');
        }}, 'timelineHalf'); // scale bottom image to full size

        timelineUp.play();

      }
      if(this.previousIndex < this.activeIndex && this.previousIndex != 0) {
        if(_self.debug) console.log('[EventsSlider] activeIndexChange DOWN', this.previousIndex, this.activeIndex, _self.currentTimeline, _self.sliderEvents1Queue);
        if(_self.currentTimeline != null) {
          if(_self.debug) console.log('[EventsSlider] activeIndexChange DOWN currentTimeline progress', _self.currentTimeline.progress());
          _self.alreadyRunning = true;
          _self.currentTimeline.timeScale(2.5);
        }
        let timelineDown = gsap.timeline({
          ease: "none",
          // ease: "power2.out",
          paused: true,
          onStart: function(){
            _self.alreadyRunning = true;
          },
          onComplete: function(){
            if(_self.debug) console.log('[EventsSlider] timelineDown complete', this, _self.sliderEvents1Queue);
            _self.currentTimeline = null;
            _self.alreadyRunning = false;
            sliderEvents1.allowTouchMove = true;
            if(_self.sliderEvents1Queue.length > 0) {
              let nextQueueItem = _self.sliderEvents1Queue.shift();
              if(_self.debug) console.log('[EventsSlider] timelineDown complete QUEUE nextQueueItem', nextQueueItem);
              if(nextQueueItem == 'DOWN') sliderEvents1.slideNext();
              if(nextQueueItem == 'UP') sliderEvents1.slidePrev();
            }
          }
        });
        let topImage = $(_self.sliderEvents3.slides[this.previousIndex]).find('.o-event__thumbnail').css('opacity', 1);
        let topImageNew = $(_self.sliderEvents3.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldTopImageNew = $(_self.sliderEvents3.slides).find('.o-event__thumbnail.oldTopImageNew');
        let centerImage = $(sliderEvents1.slides[this.previousIndex]).find('.o-event__thumbnail');
        let centerImageNew = $(sliderEvents1.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldCenterImageNew = $(sliderEvents1.slides).find('.o-event__thumbnail.oldCenterImageNew');
        let bottomImage = $(_self.sliderEvents2.slides[this.previousIndex]).find('.o-event__thumbnail').css('opacity', 1);
        let bottomImageNew = $(_self.sliderEvents2.slides[this.activeIndex]).find('.o-event__thumbnail');
        let oldBottomImageNew = $(_self.sliderEvents2.slides).find('.o-event__thumbnail.oldBottomImageNew');

        // clean up classes
        topImage.add(topImageNew).add(oldTopImageNew).add(centerImage).add(centerImageNew).add(oldCenterImageNew).add(bottomImage).add(bottomImageNew).add(oldBottomImageNew).removeClass('topImage topImageNew centerImage centerImageNew bottomImage bottomImageNew');

        // if(_self.debug) console.log('[EventsSlider] activeIndexChange DOWN', this.previousIndex, this.activeIndex,
        // 'TOP IMAGE?', topImage.addClass('topImage'),
        // 'TOP IMAGE NEW?', topImageNew.addClass('topImageNew'),
        // 'OLD TOP IMAGE NEW?', oldTopImageNew,
        // 'CENTER IMAGE?', centerImage.addClass('centerImage'),
        // 'CENTER IMAGE NEW?', centerImageNew.addClass('centerImageNew'),
        // 'OLD CENTER IMAGE NEW?', oldCenterImageNew,
        // 'BOTTOM IMAGE?', bottomImage.addClass('bottomImage'),
        // 'BOTTOM IMAGE NEW?', bottomImageNew.addClass('bottomImageNew'));
        // 'OLD BOTTOM IMAGE NEW?', oldBottomImageNew,

        // add labels
        timelineDown.addLabel('timelineStart', 0);
        timelineDown.addLabel('timelineHalf', transitionDuration/2);
        timelineDown.addLabel('timelineEnd', transitionDuration);

        // TODO: animate bottom image from bottom to center
        timelineDown.set(bottomImage, {opacity: 1, scale: 1, y: '0vh'}, 'timelineStart'); // show bottom image and place at top of screen
        timelineDown.to(bottomImage, {y: '-50vh', duration: transitionDuration, delay: transitionDelay}, 'timelineStart'); // animate bottom image to center
        timelineDown.to(bottomImage, {scale: minScale, duration: transitionDuration/2, delay: transitionDelay}, 'timelineStart'); // scale top image to half size
        timelineDown.to(bottomImage, {scale: 1, duration: transitionDuration/2, delay: transitionDelay, onComplete: function(){
          centerImageNew.addClass('oldCenterImageNew');
          topImageNew.addClass('oldTopImageNew');
          bottomImageNew.addClass('oldBottomImageNew');
        }}, 'timelineHalf'); // scale top image to full size
        timelineDown.set(bottomImage, {opacity: 0, scale: 1, y: '0vh', delay: transitionDelay}, 'timelineEnd'); // hide bottom image and place at bottom of screen
        timelineDown.set(centerImageNew, {opacity: 1, scale: 1, delay: transitionDelay}, 'timelineEnd'); // show real new center image (main slider)

        // TODO: animate NEW bottom image from down under to bottom
        timelineDown.set(bottomImageNew, {opacity: 1, scale: 1, y: '50vh'}, 'timelineStart'); // show new bottom image and place down under
        if(oldBottomImageNew.length && !oldBottomImageNew.is(bottomImage)) timelineDown.set(oldBottomImageNew, {opacity: 0, scale: 1}, 'timelineStart'); //hide old (new) top image if present
        timelineDown.to(bottomImageNew, {y: '0vh', duration: transitionDuration}, 'timelineStart');  // animate new bottom image to bottom of screen
        timelineDown.to(bottomImageNew, {scale: minScale, duration: transitionDuration/2}, 'timelineStart'); // scale new top image to half size
        timelineDown.to(bottomImageNew, {scale: 1, duration: transitionDuration/2, onStart: function(){
          oldBottomImageNew.removeClass('oldBottomImageNew');
        }}, 'timelineHalf');

        // TODO: animate top image to above the sky!
        timelineDown.set(topImage, {opacity: 1, scale: 1, y: '0vh'}, 'timelineStart'); // show top image and place at top of screen
        if(oldTopImageNew.length && !oldTopImageNew.is(topImage)) timelineDown.set(oldTopImageNew, {opacity: 0, scale: 1}, 'timelineStart'); //hide old (new) top image if present
        timelineDown.to(topImage, {y: '-50vh', duration: transitionDuration, delay: transitionDelay}, 'timelineStart'); // animate top image to above the sky!
        timelineDown.to(topImage, {scale: minScale, duration: transitionDuration/2, delay: transitionDelay}, 'timelineStart'); // scale top image to half size
        timelineDown.to(topImage, {scale: 1, duration: transitionDuration/2, delay: transitionDelay, onStart: function(){
          if(oldTopImageNew.length) oldTopImageNew.removeClass('oldTopImageNew');
        }}, 'timelineHalf'); // scale top image to full size

        // TODO: animate NEW top image from center to top
        timelineDown.set(topImageNew, {opacity: 1, scale: 1, y: '50vh'}, 'timelineStart'); // show new top image and place it at center
        timelineDown.set(centerImage, {opacity: 0, scale: 1}, 'timelineStart'); // hide real center image (main slider)
        if(oldCenterImageNew.length && !oldCenterImageNew.is(topImageNew)) timelineDown.set(oldCenterImageNew, {opacity: 0, scale: 1}, 'timelineStart'); // hide old (new) center image if present
        timelineDown.to(topImageNew, {y: '0vh', duration: transitionDuration}, 'timelineStart'); // animate new top image to center
        timelineDown.to(topImageNew, {scale: minScale, duration: transitionDuration/2}, 'timelineStart'); // scale new top image to half size
        timelineDown.to(topImageNew, {scale: 1, duration: transitionDuration/2, onStart: function(){
          oldCenterImageNew.removeClass('oldCenterImageNew');
        }}, 'timelineHalf'); // scale new top image to full size
        // timelineDown.set(bottomImageNew, {opacity: 0, scale: 0}, 'timelineEnd');
        _self.currentTimeline = timelineDown;
        sliderEvents1.allowTouchMove = false;
        timelineDown.play();
      }


    });

    // add controller for clone sliders
    // let sliderParamsClones = Object.assign({}, sliderParams);
    // sliderParamsClones.controller = {
    //   control: eventsSliderContainer1
    // };

    this.titles = $('.o-events-slider .o-event__title');
    if(_self.debug) console.log('[EventsSlider] titles ', this.titles);
    return sliderEvents1;


  }

  setSlidesHeight(swiper){

    let _self = this;
    
    if(_self.debug) console.log('[EventsSlider] setSlidesHeight ', _self.eventsSliderContainer.find('.swiper-slide'), 'swiper?', swiper);
    // _self.eventsSliderContainer.find('.swiper-slide')
    // if(swiper) {
      if(_self.debug) console.log('[EventsSlider] setSlidesHeight swiper', $(swiper.slides[swiper.activeIndex]).get(0).scrollHeight);
      $(swiper.slides).css({
        'height': '',
        'max-height': ''
      });
      let activeHeight = window.innerWidth >= 768 ? '100vh' : $(swiper.slides[swiper.activeIndex]).get(0).scrollHeight;
      $(swiper.slides).css({
        'height': activeHeight,
        'max-height': activeHeight
      });
      $(swiper.$wrapperEl).css('height', activeHeight);
      swiper.update();
      
    // } else {
      // _self.eventsSliderContainer.find('.swiper-slide').each(function(i,el){
      //   $(el).css('height', '');
      //   $(el).css('max-height', '');
      //   let newHeight = el.scrollHeight;
      //   if(_self.debug) console.log('[EventsSlider] setSlidesHeight i=', i, $(el).height(), newHeight);
      //   $(el).css('height', newHeight);
      //   $(el).css('max-height', newHeight);
      // });
    // }

  }

  resizeTitles(){

    let _self = this;
    let targetNumLines = window.innerWidth < 768 ? 2 : 2;
    let step = 1;

    this.titles.each(function(i,title){
      //reset title font sizes
      $(title).css('font-size', '');

      let titleLineHeight = parseFloat($(title).css('line-height'));
      let titleFontSize = parseFloat($(title).css('font-size'));
      let titleHeight = $(title).height();
      let targetHeight = Math.ceil(targetNumLines * titleLineHeight);
      // if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);

      // //if is greater than target number of lines
      if(titleHeight > targetHeight) {
        // reduce font size until not overflown
        while (titleHeight > targetHeight) {
          titleFontSize = parseFloat($(title).css('font-size'));
          $(title).css('font-size', (titleFontSize - step) + 'px');
          titleLineHeight = parseFloat($(title).css('line-height'));
          targetHeight = Math.ceil(targetNumLines * titleLineHeight);
          titleHeight = $(title).height();
          // if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);
        }
      }
    });

    // if(window.innerWidth < 768) this.setSlidesHeight();

  }

  initSwiperMobile() {

    let _self = this;

    // update titles
    this.titles = $('.o-events-slider .o-event__title');

    // this.resizeTitles(false);
    
    return new Swiper(_self.eventsSliderContainer.get(0), {
      slidesPerView: 1,
      direction: 'vertical',
      allowTouchMove: false,
      touchReleaseOnEdges: true,
      // spaceBetween: 28,
      spaceBetween: 0,
      watchOverflow: true,
      autoHeight: true,
      loop: true,
      rewind: false,
      loopAdditionnalSlides: 6,
      centeredSlides: true,
      observer: true,
      observeParents: true,
      resizeObserver: false,
      navigation: {
        nextEl: _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-down').get(0),
        prevEl: _self.eventsSliderContainer.find('.o-events-slider__nav .btn-arrow-up').get(0),
      },
      on: {
        slideChange: function(swiper){
          if(_self.debug) console.log('[EventsSlider] mobile SLIDE CHANGE', swiper, this, $(this.slides).eq(this.activeIndex).find('.o-event__title').text());
          _self.textLoopContainer.find('.text-loop__content').text( $(this.slides).eq(this.activeIndex).find('.o-event__title').text() );
          _self.textLoopContainer.removeClass('hidden');
          _self.textLoop.fillWithClones('.text-loop__container', '.text-loop__content');
          _self.textLoopContainer.addClass('animated');
        },
        slideChangeTransitionEnd:function(){
          if(_self.debug) console.log('[EventsSlider] mobile slideChangeTransitionEnd');
          _self.setSlidesHeight(this);
        },
        afterInit:function(){
          if(_self.debug) console.log('[EventsSlider] mobile afterInit');
          _self.setSlidesHeight(this);
        },
        resize:function(){
          if(_self.debug) console.log('[EventsSlider] mobile beforeResize');
          _self.setSlidesHeight(this);
        }
      },
      effect: this.effect,
    });


  }



}

export { EventsSlider };
