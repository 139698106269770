class ParallaxEffect {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
  }

  init() {

    let _self = this;

    // add paralax default params to double media icons in Page Type
    let doubleMediaIcons = $('.page-template-default .o-double-media-icons');
    if(_self.debug) console.log('ParallaxEffect doubleMediaIcons?', doubleMediaIcons);
    if(doubleMediaIcons.length) {

      // data attributes to elements
      let dataParallax = {
        'leftImage': {
          'data-parallax': '[{"yPercent": "15"},{"yPercent": "-35"}]',
          'data-parallax-mob': '[{"yPercent": "15"},{"yPercent": "-35"}]'
        },
        'rightImage': {
          'data-parallax': '[{"yPercent": "0"},{"yPercent": "20"}]',
          'data-parallax-mob': '[{"yPercent": "0"},{"yPercent": "20"}]'
        },
        'leftIcon': {
          'data-parallax': '[{"yPercent": "0"},{"yPercent": "25"}]',
          'data-parallax-mob': '[{"yPercent": "0"},{"yPercent": "25"}]'
        },
        'rightIcon': {
          'data-parallax': '[{"yPercent": "0"},{"yPercent": "-75"}]',
          'data-parallax-mob': '[{"yPercent": "0"},{"yPercent": "-75"}]'
        }
      };

      doubleMediaIcons.each(function(i,el){

        //add has-parallax class to parent section
        $(el).closest('.o-section').addClass('has-parallax');

        // add data attributes
        $(el).find('> .wp-block-group').each(function(i,groupEl){

          // left group
          if(i == 0) {
            // left image
            $(groupEl).find('.wp-block-image.size-pill-double-images').attr('data-parallax', dataParallax.leftImage['data-parallax']).attr('data-parallax-mob', dataParallax.leftImage['data-parallax-mob']);
            // left icon
            $(groupEl).find('.a-icon').attr('data-parallax', dataParallax.leftIcon['data-parallax']).attr('data-parallax-mob', dataParallax.leftIcon['data-parallax-mob']);
          }
          // right group
          if(i == 1) {
            // right image
            $(groupEl).find('.wp-block-image.size-pill-double-images').attr('data-parallax', dataParallax.rightImage['data-parallax']).attr('data-parallax-mob', dataParallax.rightImage['data-parallax-mob']);
            // right icon
            $(groupEl).find('.a-icon').attr('data-parallax', dataParallax.rightIcon['data-parallax']).attr('data-parallax-mob', dataParallax.rightIcon['data-parallax-mob']);
          }

        });

      });

    }


    let parallaxes = $('.has-parallax');
    if(_self.debug) console.log('ParallaxEffect parallaxes?', parallaxes);
    if(parallaxes.length) {

      parallaxes.each(function(i,parallax){
        if(_self.debug) console.log('ParallaxEffect parallax', parallax);
        if(_self.debug) console.log('ParallaxEffect parallax-target', $(parallax).find('*[data-parallax]'));

        // var theParallaxAnimation;
        // let parallaxAnimation = $(parallax).find('.a-animation');
        // if(parallaxAnimation.length) {
          // theParallaxAnimation = eval('anim'+parallaxAnimation.attr('id'));
          // if(_self.debug) console.log('ParallaxEffect animation?', theParallaxAnimation);
        // }
        let parallaxTimeline = gsap.timeline({
          ease: "none",
          scrollTrigger: {
            id: 'parallaxEffect-'+i,
            trigger: parallax,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
            markers: _self.debug,
            onEnter: function(progress, direction, isActive){
              // if(theParallaxAnimation) {
                // theParallaxAnimation.setDirection(1);
                // theParallaxAnimation.goToAndStop(0);
                // setTimeout(function(){
                  // theParallaxAnimation.play();
                // },500);
              // }
            },
            onEnterBack: function(progress, direction, isActive){
            // if(_self.debug) console.log('ParallaxEffect onEnterBack?', theParallaxAnimation);
              // if(theParallaxAnimation) {
                // theParallaxAnimation.setDirection(-1);
                // theParallaxAnimation.goToAndStop(0);
                // setTimeout(function(){
                  // theParallaxAnimation.play();
                // },500);
              // }
            }
          },
        });

        ScrollTrigger.matchMedia({

          "(min-width: 768px)": function() {
            $(parallax).find('*[data-parallax]').each(function(i,parallaxTarget){
              if(_self.debug) console.log('data-parallax', $(parallaxTarget).attr('data-parallax'));
              let parallaxParams = JSON.parse($(parallaxTarget).attr('data-parallax'));
              if(_self.debug) console.log('parallaxParams', parallaxParams);
              parallaxTimeline.fromTo(parallaxTarget, parallaxParams[0], parallaxParams[1], 0);
            });
          },
          "(max-width: 767px)": function() {
            $(parallax).find('*[data-parallax-mob]').each(function(i,parallaxTarget){
              let parallaxParams = JSON.parse($(parallaxTarget).attr('data-parallax-mob'));
              parallaxTimeline.fromTo(parallaxTarget, parallaxParams[0], parallaxParams[1], 0);
            });
          }

        });

      });

    }
  }
}

export { ParallaxEffect };
