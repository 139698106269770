class RelatedSlider {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
  }

  init() {

    let _self = this;

    let galleryRelated = $('.o-related__slider');

    if(_self.debug) console.log('RelatedSlider init', galleryRelated.find('.swiper-slide'));

    if(galleryRelated.length && galleryRelated.find('.swiper-slide').length > 1) {

      let relatedSlider = new Swiper($(galleryRelated).get(0), {
        slidesPerView: 'auto',
        autoHeight: true,
        // slidesPerColumn: 2,
        spaceBetween: 54,
        // slidesPerGroup: 2,
        // centeredSlides: true,
        // spaceBetween: -5,
        // loop: true,
        // loopFillGroupWithBlank: true,
        // zoom: true,
        navigation: {
          nextEl: $(galleryRelated).find('.o-related__slider-next').get(0),
          prevEl: $(galleryRelated).find('.o-related__slider-prev').get(0),
        },
        breakpoints: {
          1024: {
            spaceBetween: 66,
          },
          1280: {
            spaceBetween: 76,
          },
          1440: {
            spaceBetween: 88,
          },
        }

      });
      if(_self.debug) console.log('RelatedSlider initialized ?', relatedSlider);

    }



  }
}

export { RelatedSlider };
