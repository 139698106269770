import Panzoom from '@panzoom/panzoom';

class Map99Bis {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    window.Map99Bis = this;

  }

  init() {

    let _self = this;


    this.mapWrapper = $('#map-wrapper').removeClass('hidden');
    this.mapContainer = $('#map');

    if(this.mapContainer.length) {

      document.addEventListener('alpine:init', () => {

        if(_self.debug) console.log('Alpine init !!')

        Alpine.data('mapneufneufbis', () => ({

          mapContainer: _self.mapContainer,
          mapWrapper: _self.mapWrapper,
          expanded: false,
          markers: {},
          currentMarkerId: null,
          mapTilePos: '',

          open(e) {
            if(_self.debug) console.log('mapneufneufbis open e', e, typeof e.detail.place, _self.panzoom.getPan());
            // if(_self.debug) alert(_self.panzoom.getPan().x + ' '+_self.panzoom.getPan().y);

            // _self.panzoom.reset({ animate: false });
            _self.mapContainer.width(_self.mapContainer.find('img').width());
            _self.panzoom.pan(1, 1, { relative: true });

            this.expanded = true;
            if(e.detail.place) this.showMarker(e.detail.place);
          },

          close() {
            // if(_self.debug) alert(_self.panzoom.getPan().x + ' '+_self.panzoom.getPan().y);
            this.expanded = false;
          },

          toggle() {
            // console.log('TOGGLE alpine', this.expanded);
            // if(_self.debug) alert(_self.panzoom.getPan().x + ' '+_self.panzoom.getPan().y);
            this.expanded = !this.expanded;
          },

          showMarker(id) {
            if(_self.debug) console.log('showMarker id:', id, this.markers[id], ' x', this.markers[id].coords.x, ' y', this.markers[id].coords.y);
            this.currentMarkerId = id;
            let mapW = this.mapContainer.width();
            let mapInnerW = this.mapContainer.closest('.t-map__container-inner').width();
            let newX = (((mapW-mapInnerW)*(this.markers[id].coords.x / 100)) * -1 + (((50/100) - this.markers[id].coords.x / 100) * mapInnerW));
            if(_self.debug) console.log('showMarker newX', newX, 'mapW', mapW, 'mapInnerW', mapInnerW,  this.markers[id].coords.x, this.markers[id].coords.x / 100, ((50/100) - this.markers[id].coords.x / 100), this.currentMarkerId);
            _self.panzoom.pan(newX, 0);
            this.mapContainer.find('.t-map__marker.is-active').removeClass('is-active');
            $(this.markers[id].element).addClass('is-active');
            this.mapTilePos = 'left: calc('+this.markers[id].coords.x+'%);top: calc('+this.markers[id].coords.y+'% + '+($(this.markers[id].element).height()+20)+'px);';
            if(_self.debug) console.log('showMarker mapTilePos', this.mapTilePos);
          },

          selectMarker(id) {
            if(_self.debug) console.log('selectMarker id?', id, $('#place-'+id));
            _self.placesSwiper.slideTo($('#place-'+id).closest('li').index());
            this.showMarker(id);
          },

          unselectMarker(){
            if(_self.debug) console.log('unselectMarker current?', this.currentMarkerId);
            this.currentMarkerId = null;
            this.mapContainer.find('.t-map__marker.is-active').removeClass('is-active');
            if(_self.debug) console.log('unselectMarker current=', this.currentMarkerId);
          },

          // TODO: click anywhere on map -> close info tile
          // TODO: at screen > 768 position tile near marker (and take screen edges into account)

          init() {

            let _this = this;

            if(_self.debug) console.log('map99bis Alpine init');


            if(_self.debug) console.log('map99bis _self.panzoom', _self.panzoom);

            this.mapContainer.find('.t-map__marker').each(function(i,el){

              let place_coords = $(el).attr('data-place-coords').split(',');
              let markerData = {
                coords: {
                  x: place_coords[0],
                  y: place_coords[1]
                },
                element: el,
              };
              if(_self.debug) console.log('map99bis id', $(el).attr('data-place-id'), ' markerData', markerData);
              // id: $(el).attr('data-place-id'),

              _this.markers[$(el).attr('data-place-id')] = markerData;

            });

            // reset panzoom just in case it fixes bugs?
            _self.panzoom.reset({ animate: false });

            if(_self.debug) console.log('[Map99Bis] window.location', window.location);
            if(window.location.hash != '' && window.location.hash == '#open-map') _this.open(new CustomEvent('open-map', { detail: {place: null}}));

          }

        }));

      });

      if(_self.debug) console.log('Map99Bis init', $('#map-container'), 'Panzoom', Panzoom);

      this.mapContainer.width(this.mapContainer.find('img').width());

      const panzoom = Panzoom(this.mapContainer.get(0), {
        // maxScale: 5,
        // startScale: 2,
        // origin: '50% 100%',
        startX: this.mapContainer.width() / -2,
        startY: this.mapContainer.height() / -2,
        animate: true,
        contain: 'outside',
        disableYAxis: true,
      });

      this.panzoom = panzoom;


      this.placesSwiper = new Swiper(this.mapWrapper.find('.t-map__places').get(0), {
        slidesPerView: 'auto',
        autoHeight: true,
        spaceBetween: 20,
        watchOverflow: true,
        slideToClickedSlide: true,
        threshold: 20,
        direction: _self.placesSwiperGetDirection(),
        // direction: 'horizontal',
        // navigation: {
        //   nextEl: $(pageMenu).find('.t-service__menu-next').get(0),
        //   prevEl: $(pageMenu).find('.t-service__menu-prev').get(0),
        // },
        breakpoints: {
          768: {
            // watchOverflow: false,
            // virtualTranslate: true,
            autoHeight: false,
            spaceBetween: 15,
            // grid: {
            //   fill: 'row',
            //   rows: this.mapWrapper.find('.t-map__places').find('.swiper-slide').length
            // },
            // direction: 'vertical'
            // slidesPerView: pageMenu.find('.swiper-slide').length,
          },
          // 1024: {
          //   spaceBetween: -10,
          // },
          // 1280: {
          //   spaceBetween: -20,
          // },
        },
        on: {
          resize: function () {
            this.changeDirection(_self.placesSwiperGetDirection());
            _self.mapContainer.width(_self.mapContainer.find('img').width());
          }
          // slideChangeTransitionStart: function(){
          //   // console.log('pageMenuSwiper slideChangeTransitionStart', this.activeIndex);
          //   _self.scrollToTargetAdjusted($($(this.slides[this.activeIndex]).find('a').attr('href')));
          //   // target.scrollIntoView({
          //   //   behavior: "smooth",
          //   //   block: "start"
          //   // });
          // }
        }

      });

      // open map
      $(document).on('click', '.js-map99bis-toggle', this.toggle.bind(this));


      // open map
      $(document).on('click', '.js-map99bis-open', this.open.bind(this));
      $(document).on('click', 'a[href*="#open-map"]', this.open.bind(this));

      // close map
      $(document).on('click', '.js-map99bis-close', this.close.bind(this));

      $(window).on('resize', _.debounce(this._resize.bind(this), 400));
      this._resize();
     
    }

  }

  placesSwiperGetDirection() {
    var windowWidth = window.innerWidth;
    var direction = window.innerWidth >= 768 ? 'vertical' : 'horizontal';

    return direction;
  }

  open(e) {

    if(this.debug) console.log('Map99Bis open e', e, typeof e);

    let placeId = null;
    if(typeof e !== 'number' && e) {
      e.preventDefault();
      e.stopPropagation();

      $(e.target).blur();
    }
    if(typeof e === 'number') {
      placeId = e;
    }


    // this.mapWrapper.attr('aria-expanded', true);
    this.mapWrapper.get(0).dispatchEvent(new CustomEvent('open-map', { detail: {place: placeId}}))

  }

  close(e) {

    e.preventDefault();
    e.stopPropagation();

    // this.mapWrapper.attr('aria-expanded', false);
    this.mapWrapper.get(0).dispatchEvent(new CustomEvent('close-map', { detail: {}}))

    $(e.target).blur();

  }

  toggle(e) {

    e.preventDefault();
    e.stopPropagation();

    this.mapWrapper.get(0).dispatchEvent(new CustomEvent('toggle-map', { detail: {}}))
    // this.mapWrapper.attr('aria-expanded', this.mapWrapper.attr('aria-expanded') == "true" ? "false" : "true");

    $(e.target).blur();

  }

  _resize(){

    let _self = this;
    if(_self.debug) console.log('[Map99Bis] _resize mapContainer.width', _self.mapContainer.width(), 'img width', _self.mapContainer.find('img').width());
    _self.mapContainer.width(_self.mapContainer.find('img').width());
    _self.panzoom.pan(1, 1, { relative: true });

  }

}

export { Map99Bis };
