class Animations {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    if(this.debug) console.log('Animations constructor');
    this.logoAnim = null;
    this.logoAnimStartTimeout = null;
    this.logoAnimEndTimeout = null;

  }

  init() {

    let _self = this;

    var url = new URL(window.location.href);
    this.debug = !!url.searchParams.get("debug") || this.debug;
    if(_self.debug) console.log('Animations debug enabled', _self.debug);

    if(_self.debug) console.log('$ ?', $, 'lottie?', lottie);

    var lottieAnimations = lottie.getRegisteredAnimations();
    var lottieScrollTriggers = [];
    // animate/pause lottie when scrolled in view/out of view
    if(lottieAnimations.length) {
      $.each(lottieAnimations, function(i,lottieAnimation){

        if(lottieAnimation.name.match(/^Logo99bis/gm)) {
          if(!_self.logoAnim) _self.logoAnim = lottieAnimation;
        }
        if(_self.debug) console.log('lottieAnimation', lottieAnimation);
          var lottieScrollTrigger = ScrollTrigger.create({
            id: lottieAnimation.name || 'lottieAnimation-'+i,
            trigger: lottieAnimation.wrapper,
            start: "top bottom",
            end: "bottom top",
            onToggle: self => {
              if(_self.debug) console.log("toggled, lottieAnimation"+i+" isActive:", self.isActive);
              if(window.innerWidth < 1280 || (lottieAnimation.autoplay && lottieAnimation.loop)) {
                //is it in view ?
                if (self.isActive) {
                  lottieAnimation.play();
                } else {
                  lottieAnimation.pause();
                }

              }
              // home logo
              if(lottieAnimation.name.match(/^Logo99bis/gm)) {

                if (self.isActive) {
                  _self.logoAnimStart();
                } else {
                  _self.logoAnimStop();
                }

              }

            },
            markers: _self.debug,
          });

          lottieScrollTriggers.push(lottieScrollTrigger);
        if(lottieAnimation.autoplay && lottieAnimation.loop) {
        } else if(lottieAnimation.name.match(/^Logo99bis/gm)) {
          if(_self.debug) console.log('lottieAnimation Logo99bis', $(lottieAnimation.wrapper));
          if(!_self.logoAnim) _self.logoAnim = lottieAnimation;
        } else {
          if(_self.debug) console.log('lottieAnimation MOUSEENTER', $(lottieAnimation.wrapper))
          $(lottieAnimation.wrapper).on('mouseenter', { animation: lottieAnimation, _this: _self }, _self.playAnimationOnMousenter);
          if($(lottieAnimation.wrapper).closest('.card').length) $(lottieAnimation.wrapper).closest('.card').on('mouseenter', { animation: lottieAnimation, _this: _self }, _self.playAnimationOnMousenter);


        }

        if(lottieAnimation.autoplay == false && lottieAnimation.loop == false && lottieAnimation != _self.logoAnim) {
          lottieAnimation.onComplete = function(e) {
            if(_self.debug) console.log('animation complete?', e, this);
            this.goToAndStop(0);
            if(_self.debug) console.log('animation complete', e);
            //e.data.animation.name);
            // $(e.data.animation.wrapper).one('mouseenter', { animation: e.data.animation }, _self.playAnimationOnMousenter);
          };
        }

      });

      // freeze/unfreeze lottie when window is visible/hidden
      document.addEventListener('visibilitychange', function (event) {
        if (document.hidden) {
          if(_self.debug) console.log('not visible');
          lottie.freeze();
        } else {
          if(_self.debug) console.log('is visible');
          lottie.unfreeze();
        }
      });

    }
  }

  playAnimationOnMousenter(e){

    let _self = this;

    if(e.data._this.debug) console.log('playAnimationOnMousenter e', e, 'e.data.animation', e.data.animation);
    if(e.data.animation.isPaused) {
      //rewind
      e.data.animation.goToAndStop(0);
      e.data.animation.setDirection(1);
      e.data.animation.play();
      if(e.data.autoplay == false && e.data.loop == false) {
        e.data.animation.onComplete = function(e) {
          if(_self.debug) console.log('animation complete?', e, this);
          this.goToAndStop(0);
          if(_self.debug) console.log('animation complete', e);
          //e.data.animation.name);
          // $(e.data.animation.wrapper).one('mouseenter', { animation: e.data.animation }, _self.playAnimationOnMousenter);
        };
      }
      // e.data.animation.addEventListener('complete', function(e) {
      //   console.log('animation complete?', e, this);
      //   if(_self.debug) console.log('animation complete', e);
      //   //e.data.animation.name);
      //   // $(e.data.animation.wrapper).one('mouseenter', { animation: e.data.animation }, _self.playAnimationOnMousenter);
      // }, {once: true});
    }
  }

  logoAnimStop(){

    let _self = this;

    // rewind, remove events and clear timeouts
    _self.logoAnim.goToAndStop(0);
    _self.logoAnim.removeEventListener('complete');
    clearTimeout(_self.logoAnimStartTimeout);
    clearTimeout(_self.logoAnimEndTimeout);

  }

  logoAnimStart(){

    let _self = this;

    // set variables
    var pauseMiddle = 4000;
    var pauseRestart = 2000;
    var promise_start = null;
    var promise_end = null;

    // stop to prevent the sequence loop running amok
    _self.logoAnimStop();

    // animation loop sequence
    promise_start = _self.animate(_self.logoAnim, [_self.seconds(0), _self.seconds(2.5)]);
    promise_start.then(() => {
      if(_self.debug) console.log('logoAnim animate 0 to 2.5 done.');
      _self.logoAnim.pause();
      _self.logoAnimStartTimeout = setTimeout(() => {
        if(_self.debug) console.log('logoAnim animating from 2.5 to 5.');
        promise_end = _self.animate(_self.logoAnim, [_self.seconds(2.5), _self.seconds(5)]);
        promise_end.then(() => {
          if(_self.debug) console.log('logoAnim animate 2.5 to 5 done.');

          _self.logoAnimEndTimeout = setTimeout(() => {
            _self.logoAnimStart();
            if(_self.debug) console.log(`logoAnimStart again after ${pauseRestart} ms!`);
          }, pauseRestart);
        });

      }, pauseMiddle);
    });


  }
  seconds(v) {
    return v * 25
  }

  async animate(anim, segment) {
      anim.playSegments(segment, true)

      return new Promise(function(resolve, reject) {
          anim.addEventListener('complete', function(e) {
              resolve(e.data); // done
          }, {once: true});
      });
  }

}

export { Animations };
