class FullscreenDoubleSlider {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;

  }

  init() {

    let _self = this;

    let fullscreenDoubleSliders = $('.o-fullscreen-double-slider');
    if(_self.debug) console.log('FullscreenDoubleSlider init', fullscreenDoubleSliders.find('.swiper-slide'));

    if(window.innerWidth >= 768) {

      if(fullscreenDoubleSliders.length && fullscreenDoubleSliders.find('.swiper-slide').length > 1) {
      fullscreenDoubleSliders.each(function(i,fullscreenDoubleSlider){
        if(_self.debug) console.log('fullscreenDoubleSlider?', fullscreenDoubleSlider);
        let fullscreenDoubleSwiper1 = new Swiper($(fullscreenDoubleSlider).find('.o-fullscreen-double-slider__1').get(0), {
          slidesPerView: 1,
          autoHeight: true,
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          // autoplay: true,
          direction: 'vertical',
          allowTouchMove: false,
          // mousewheel: {releaseOnEdges: true},
          on:{
            slideChange: (swiper) => {
              // const {offsetTop} = swiper.el;
              // window.pageYOffset !== offsetTop && window.scrollTo({
              //   top: offsetTop,
              //   behavior: 'smooth'
              // });
              $(swiper.$el).find('.o-fullscreen-double-slider__1-decoration svg').each(function(i,el){
                el.classList.remove('active');
              });
              if(_self.debug) console.log('fullscreenDoubleSwiper1 slideChange', $(swiper.slides[swiper.activeIndex]).find('.o-fullscreen-double-slider__1-decoration svg'));
              $(swiper.slides[swiper.activeIndex]).find('.o-fullscreen-double-slider__1-decoration svg').get(0).classList.add('active');
            },
            slideChangeTransitionEnd: (swiper) => {
              // const activeIndex = swiper.activeIndex;
              // swiper.params.mousewheel.releaseOnEdges = activeIndex === 0 || (activeIndex === swiper.slides.length-1);
              if(swiper.activeIndex != fullscreenDoubleSwiper2.activeIndex) {
                if(_self.debug) console.log('SYNC fullscreenDoubleSwiper2 with fullscreenDoubleSwiper1', swiper.activeIndex)
                fullscreenDoubleSwiper2.slideTo(swiper.activeIndex);
                setTimeout(() => {
                  fullscreenDoubleSwiper1_front.slideTo(swiper.activeIndex);
                }, 200);
              }
              if(_self.debug) console.log('fullscreenDoubleSwiper1 slideChangeTransitionEnd activeIndex ', swiper.activeIndex, 'slides.length ', swiper.slides.length,swiper.activeIndex < swiper.slides.length-1,  fullscreenDoubleSliders.find('.btn-scroll'));
              if(swiper.activeIndex < swiper.slides.length-1) _self.btnScroll.removeClass('is-hidden');
              else _self.btnScroll.addClass('is-hidden');
            }
          },
        });


        let fullscreenDoubleSwiper1_front = new Swiper($(fullscreenDoubleSlider).find('.o-fullscreen-double-slider__1').get(1), {
          slidesPerView: 1,
          autoHeight: true,
          effect: 'fade',
          speed: 800,
          // autoplay: true,
          direction: 'vertical',
          allowTouchMove: false,
          // mousewheel: {releaseOnEdges: true},
          on:{
            slideChange: (swiper) => {
              // const {offsetTop} = swiper.el;
              // window.pageYOffset !== offsetTop && window.scrollTo({
              //   top: offsetTop,
              //   behavior: 'smooth'
              // });
            },
            slideChangeTransitionEnd: (swiper) => {
              // const activeIndex = swiper.activeIndex;
              // swiper.params.mousewheel.releaseOnEdges = activeIndex === 0 || (activeIndex === swiper.slides.length-1);
              if(swiper.activeIndex != fullscreenDoubleSwiper2.activeIndex) {
                if(_self.debug) console.log('SYNC fullscreenDoubleSwiper2 with fullscreenDoubleSwiper1', swiper.activeIndex)
                fullscreenDoubleSwiper2.slideTo(swiper.activeIndex);
                setTimeout(() => {
                  fullscreenDoubleSwiper1.slideTo(swiper.activeIndex);
                }, 200);
              }
            }
          },
        });
        let fullscreenDoubleSwiper2 = new Swiper($(fullscreenDoubleSlider).find('.o-fullscreen-double-slider__2').get(0), {
          slidesPerView: 1,
          autoHeight: true,
          // effect: 'fade',
          // autoplay: true,
          direction: 'vertical',
          // mousewheel: {
          //   eventsTarget: fullscreenDoubleSliders.get(0),
          //   releaseOnEdges: true,
          //   thresholdDelta: 20,
          //   // sensitivity: 0.1
          // },
          // followFinger: false,
          // touchReleaseOnEdges: true,
          allowTouchMove: false,
          longSwipes: true,
          on:{
            slideChange: (swiper) => {
              // const {offsetTop} = swiper.el;
              // window.pageYOffset !== offsetTop && window.scrollTo({
              //   top: offsetTop,
              //   behavior: 'smooth'
              // });
            },
            slideChangeTransitionEnd: (swiper) => {
              // const activeIndex = swiper.activeIndex;
              // swiper.params.mousewheel.releaseOnEdges = activeIndex === 0 || (activeIndex === swiper.slides.length-1);
              if(swiper.activeIndex != fullscreenDoubleSwiper1.activeIndex) {
                if(_self.debug) console.log('SYNC fullscreenDoubleSwiper1 with fullscreenDoubleSwiper2', swiper.activeIndex)
                fullscreenDoubleSwiper1.slideTo(swiper.activeIndex);
                fullscreenDoubleSwiper1_front.slideTo(swiper.activeIndex);
              }
            }
          },
          // thumbs: {
          //   swiper: fullscreenDoubleSwiper1,
          //   multipleActiveThumbs: false
          // },
          // controller: {
          //   control: fullscreenDoubleSwiper1
          // }
          // slidesPerColumn: 2,
          // spaceBetween: 0,
          // slidesPerGroup: 2,
          // centeredSlides: true,
          // spaceBetween: -5,
          // loop: true,
          // loopFillGroupWithBlank: true,
          // navigation: {
          //   nextEl: $(gallery).find('.o-gallery__next').get(0),
          //   prevEl: $(gallery).find('.o-gallery__prev').get(0),
          // },
          // scrollbar: {
          //   el: $(gallery).find('.o-gallery__scrollbar').get(0),
          //   hide: false,
          //   draggable: true,
          //   // dragSize: 60
          // },
          // breakpoints: {
          //   768: {
          //     slidesPerView: 'auto'
          //   },
          //   // 1024: {
          //   //   spaceBetween: -10,
          //   // },
          //   // 1280: {
          //   //   spaceBetween: -20,
          //   // },
          // }

        });
        if(_self.debug) console.log('FullscreenDoubleSlider initialized ?', fullscreenDoubleSwiper2);

        let tl = gsap.timeline({
          // yes, we can add it to an entire timeline!
          scrollTrigger: {
            trigger: $('.t-home__places').get(0),
            pin: true,   // pin the trigger element while active
            anticipatePin: 1,
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: fullscreenDoubleSwiper2.slides.length+"00%", // end after scrolling 500px beyond the start
            // end: "200%", // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: _self.debug,
            onEnter: self => {

              if(_self.debug) console.log('FullscreenDoubleSlider scrollTrigger onEnter', $(fullscreenDoubleSwiper1.slides[fullscreenDoubleSwiper1.activeIndex]).find('.o-fullscreen-double-slider__1-decoration svg'));
              $(fullscreenDoubleSwiper1.slides[fullscreenDoubleSwiper1.activeIndex]).find('.o-fullscreen-double-slider__1-decoration svg').get(0).classList.add('active');
            },
            onUpdate: self => {
              if(_self.debug) console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity(), 'GOTO slide ', self.progress.toFixed(3) * fullscreenDoubleSwiper2.slides.length, Math.floor(self.progress.toFixed(3) * fullscreenDoubleSwiper2.slides.length+1)-1);
              let slideToGo = Math.floor(self.progress.toFixed(3) * fullscreenDoubleSwiper2.slides.length+1) - 1;
              if(fullscreenDoubleSwiper2.activeIndex != slideToGo) fullscreenDoubleSwiper2.slideTo(slideToGo);

            },
            // snap: {
            //   snapTo: "labels", // snap to the closest label in the timeline
            //   // duration: {min: (fullscreenDoubleSwiper2.params.speed * 2 / 1000), max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //   // delay: (fullscreenDoubleSwiper2.params.speed * 2 / 1000), // wait 0.2 seconds from the last scroll event before doing the snapping
            //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
            // }
          }
        });

        if(_self.debug) console.log('FullscreenDoubleSlider tl', fullscreenDoubleSwiper2.slides.length+"00%", tl);

        _self.btnScroll = fullscreenDoubleSliders.find('.btn-scroll');
        _self.btnScroll.on('click', function(e){
          e.preventDefault();
          e.stopPropagation();
          let newProgress = (fullscreenDoubleSwiper1.activeIndex+1)/fullscreenDoubleSwiper1.slides.length;
          if(_self.debug) console.log('FullscreenDoubleSlider btnScroll newProgress', newProgress, 'new scroll pos', tl.scrollTrigger.start + newProgress * (tl.scrollTrigger.end - tl.scrollTrigger.start));
          tl.scrollTrigger.scroll(tl.scrollTrigger.start + newProgress * (tl.scrollTrigger.end - tl.scrollTrigger.start));
        })

        // window.tl = tl;


      });


    }

    }



  }
}

export { FullscreenDoubleSlider };
