
class Tabs {
  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;

  }

  init() {

    let _self = this;
    
    let tabs_groups = $('.tabs');
    if(_self.debug) console.log('Tabs init', tabs_groups, tabs_groups.nextAll('.tab-item'));
    if(tabs_groups.length) {
      tabs_groups.each(function(i,tabs){
        if(_self.debug) console.log('tabs?', tabs, $(tabs).nextAll('.tab-item'));
        let tabsItems = $(tabs).nextAll('.tab-item');
        if(tabsItems.length > 1) {
          //add swiper markup
          $(tabs).addClass('swiper').wrapInner('<div class="swiper-wrapper"></div>');
          $(tabs).find('.tab').addClass('swiper-slide').on('click', function(e){ return false; });
          var swiperTabs = new Swiper(tabs, {
            slidesPerView: 'auto',
            // grid: {
            //   rows: 2,
            // },
            watchSlidesProgress: true,
            // allowTouchMove: false,
            // shortSwipes: false,
            // spaceBetween: 10,
            // freeMode: true,
          });
          tabsItems.wrapAll('<div class="swiper"><div class="swiper-wrapper"></div></div>');
          tabsItems.addClass('swiper-slide');
          //.css('backgroundColor', 'white');
          var swiperTabsContent = new Swiper(tabsItems.closest(".swiper").get(0), {
            slidesPerView: 1,
            spaceBetween: 20,
            effect: 'fade',
            thumbs: {
              swiper: swiperTabs,
              slideThumbActiveClass: 'tab-active'
            },
            breakpoints: {
              768: {
                spaceBetween: 24
              },
              1280: {
                spaceBetween: 30
              },
            }
          });
        }
      });
    }



  }
}

export { Tabs };
