class Menu99Bis {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;

  }

  init() {

    let _self = this;

    if(_self.debug) console.log('Menu99Bis init vavavooom!', $('body'));

    window.addEventListener("scroll", event => {
      let fromTop = window.scrollY + 0;

      if(fromTop >= 10) {
        if(!$('body').hasClass('is-scrolled')) {
          $('body').addClass('is-scrolled');
        }
      } else {
        if($('body').hasClass('is-scrolled')) {
          $('body').removeClass('is-scrolled');
        }
      }

    });


    this.menu = $('#menu-navigation');
    this.header = $('.o-header');
    //mobile-force-submenu
    let menuItemAgenda = $('.mobile-force-submenu .tab2hidden');
    this.menuItemAgenda = menuItemAgenda.length ? menuItemAgenda.get(0).outerHTML : null;
    if(_self.debug) console.log('menuItemAgenda', this.menuItemAgenda);
    $(window).on('resize', _.debounce(this._resize.bind(this), 400));
    this._resize();

  }

  closeMenus() {

    this.menu.find('.menu-item-has-children').removeClass('is-active');
    this.menu.removeClass('has-active-submenu');
    this.menu.closest('nav').removeClass('has-active-submenu');
    this.header.removeClass('has-active-submenu');

  }

  _resize() {

    let _self = this;
    if(_self.debug) console.log('Menu99Bis _resize', $(window).width(), window.innerWidth);

    // toggle mobile menu
    $('#menu-open:not(.has-events-click)').on('click touchend', function(e) {

        e.preventDefault();
        e.stopPropagation();

        if($(this).attr('aria-expanded') == "true") {

            // close menu
            $(this).attr('aria-expanded', false);
            _self.header.removeClass('is-open');
            window.Map99Bis.close(e);

            setTimeout(function(){

              // close sub and subsub menus
              _self.closeMenus();

            }, 400);

        } else {

          // open menu
          $(this).attr('aria-expanded', true);
          _self.header.addClass('is-open');

        }

        // unfocus menu button
        $(this).blur();
        //close Map99Bis
        window.Map99Bis.close(e);

    }).addClass('has-events-click');


    if(window.innerWidth < 1024) { // if mobile

      // add submenu return links
      if(!this.menu.hasClass('has-return-links')) {

        // add tab2hidden item (Agenda) if not present
        if($('.mobile-force-submenu .tab2hidden').length == 0) {
          $('.mobile-force-submenu .sub-menu').prepend(this.menuItemAgenda);
        }

        this.menu.find('> .menu-item-has-children').each(function(i,el){

          // create and insert return link (clone of parent link)
          let clone = $(el).clone().addClass('clone');
          clone.children('.sub-menu').remove();
          clone.find('button').remove();
          $(el).children('.sub-menu').prepend(clone);

          // click on return link
          clone.children('a:first').on('click touchend', function(e){

            e.preventDefault();
            e.stopPropagation();

            // trigger click to return to previous level
            let parentLi = $(this).closest('ul.sub-menu').closest('li');
            if(parentLi.hasClass('is-active')) {
              parentLi.children('a:first').trigger('click');
            }

          });

        });

        this.menu.addClass('has-return-links');



      }

      // 2nd level toggle submenu
      this.menu.find('> .menu-item-has-children > a:not(.has-events-click)').on('click touchend', function(e) {

        e.preventDefault();
        e.stopPropagation();

        if($(this).parent().hasClass('is-active')) {

          //close submenu
          let activeParent = $(this).parent();
          setTimeout(function(){
            // console.log('close submenu', activeParent.text());
            activeParent.removeClass('is-active');
          },400)
          _self.menu.removeClass('has-active-submenu');
          _self.menu.closest('nav').removeClass('has-active-submenu');
          _self.header.removeClass('has-active-submenu');

        } else {

          //open submenu
          // console.log('open submenu?', $(this).parent().text());
          _self.menu.find('.menu-item-has-children').removeClass('is-active');
          $(this).parent().addClass('is-active');
          _self.menu.addClass('has-active-submenu');
          _self.menu.closest('nav').addClass('has-active-submenu');
          _self.header.addClass('has-active-submenu');

        }

      }).addClass('has-events-click');


    } else { // if desktop
      // console.log('Menu99Bis it is desktop');
      // remove clones (and their events first)
      let clones = this.menu.find('.menu-item-has-children.clone');
      // console.log('Menu99Bis clones', clones);
      if(clones.length) clones.off('click touchend').remove();
      this.menu.removeClass('has-return-links');


      // remove click events for 2nd level
      let linksLevel2 = this.menu.find('> .menu-item-has-children > a.has-events-click');
      if(linksLevel2.length) linksLevel2.off('click touchend').removeClass('has-events-click');


      // close menu, sub and subsub menus
      $('#menu-open').attr('aria-expanded', false);
      this.closeMenus();

      // remove tab2hidden item (Agenda) if present
      $('.mobile-force-submenu .tab2hidden').remove();

    }

  }


}

export { Menu99Bis };
