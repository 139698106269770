import barba from '@barba/core';

class SelectSlider {
  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
  }

  init() {

    let _self = this;

    let selectSliders = $('div[data-barba="container"]:last-child .m-select-slider');
    if(_self.debug) console.log('selectSlider init', selectSliders);

    if(selectSliders.length) {

      let firstLoad = true;

      selectSliders.each(function(i,selectSlider){

        if(_self.debug) console.log('selectSlider?', selectSlider);
        if(_self.debug) console.log('selectSlider prev?', $(selectSlider).find('.m-select-slider__prev'));
        if(_self.debug) console.log('selectSlider next?', $(selectSlider).find('.m-select-slider__next'));
        if(_self.debug) console.log('selectSlider initialSlide?', $(selectSlider).find('.swiper-slide.is-current').index());
        let selectSliderSwiper = new Swiper(selectSlider, {
          slidesPerView: 1,
          initialSlide: $(selectSlider).find('.swiper-slide.is-current').index(),
          // navigation: {
          //   prevEl: $(selectSlider).find('.m-select-slider__prev'),
          //   nextEl: $(selectSlider).find('.m-select-slider__next'),
          // },
          navigation: {
            prevEl: $(selectSlider).find('.m-select-slider__prev').get(0),
            nextEl: $(selectSlider).find('.m-select-slider__next').get(0),
          },
          on: {
            'slideChangeTransitionEnd': function () {
               if(firstLoad && this.previousIndex != this.params.initialSlide) {
                 firstLoad = false;
               } else {
                 if ($('.page-template-agenda').length > 0 ){ // Filter Agenda Template On YearMonth Slider Changed

                  firstLoad = false;
                   _self.filter_agenda_events();
                   /*$('.o-event').each(function(){
                     if ($(this).attr('data-event-passed') === '1'){
                       $(this).remove();
                     }
                   });*/
                  //  $('.t-agenda__events-list').html($('div[data-barba="container"]:last .o-event').sort(_self.sortEventsByStartDate));
                 }
               }
            },
          }
        });
        if($(selectSlider).hasClass('year-month')) window.SelectSlider = selectSliderSwiper;

        // Manage Dropdown Filters On Change
        $('div[data-barba="container"]:last-child #agendaFilterCategories').on('change', _self.filter_agenda_events);
        $('div[data-barba="container"]:last-child #agendaFilterPublics').on('change', _self.filter_agenda_events);

        $('.t-agenda__nav .btn-arrow-right').on('click',function(e){
          e.preventDefault();
          e.stopPropagation();
          selectSliderSwiper.slideNext();
        });

        $('.t-agenda__nav .btn-arrow-left').on('click',function(e){
          e.preventDefault();
          e.stopPropagation();
          selectSliderSwiper.slidePrev();
        });


      });

      /*$('.o-event').each(function(){
        if ($(this).attr('data-event-passed') === '1'){
          $(this).remove();
        }
      });*/

      // $('.t-agenda__events-list').html($('div[data-barba="container"]:last .o-event').sort(_self.sortEventsByStartDate));
    }

    function getTransitionEndEventName() {
      var transitions = {
          "transition"      : "transitionend",
          "OTransition"     : "oTransitionEnd",
          "MozTransition"   : "transitionend",
          "WebkitTransition": "webkitTransitionEnd"
       }
      let bodyStyle = document.body.style;
      for(let transition in transitions) {
          if(bodyStyle[transition] != undefined) {
              return transitions[transition];
          }
      }
    }

    this.transitionEndEventName = getTransitionEndEventName();

  }

  /* Generic Filter Method */
  filter_agenda_events(){

    let _self = this;

    let year_month = $('.page-template-agenda .t-agenda__filters-year-month .swiper-slide-active').attr('data-value');
    let category_id = $('div[data-barba="container"]:last-child #agendaFilterCategories').val();
    let public_id = $('div[data-barba="container"]:last-child #agendaFilterPublics').val();

    //alert('refresh !');
    let busy;

    // Avoid Nervous Clicks !
    if ( busy )
      busy.abort();

    // Ajax Submit Form
    busy = $.ajax({
      type: "post",  url: scriptsJsObject.ajaxurl, data: { action: 'mkwvs_agenda_filter', year_month: year_month, categorie_id: category_id, public_id: public_id },
      beforeSend: function () {
        $('.t-agenda__events-list').html(''); // Clear Content
        /*$('html,body').animate({
          scrollTop: $(".t-agenda__landing").offset().top
        }, 2000);*/

        // Show Loader ?
      },
      success: function (response) {

        // Refresh Year Month
        $('.t-agenda__events h2').html(response.current_month_year);

        if (response.success === true) {
          // Refreshed Results From Ajax Response
          $('.t-agenda__events-list').html(response.html);
          // barba.history.add(url: string, trigger: Trigger)
          // Reorder Elements by data-start-date
          //$('.t-agenda__events-list').html();

        } else {
          // No Results Feedback
          var msg_error = '<p class="error">Aucun évènement trouvé</p>';
          $('.t-agenda__events-list').html(msg_error);
        }

        let newUrl = agenda_url;
        newUrl += (year_month != '' && year_month != null) ? year_month+'/' : '';
        newUrl += (category_id != '' && category_id != null) ? 'categories/'+category_id+'/' : '';
        newUrl += (public_id != '' && public_id != null) ? 'publics/'+public_id+'/' : '';
        // console.log('filter_agenda_events newUrl '+newUrl, barba);
        barba.history.add(newUrl, 'barba');

      },
      error: function (response) { alert('error'); console.log(response); },
      complete: function (response) {
        // $('.t-agenda__events-list').html($('div[data-barba="container"]:last .o-event').sort(_self.sortEventsByStartDate));
        if (response.success === true){
          setTimeout(function() {
            // $('.t-agenda__events-list').html($('div[data-barba="container"]:last .o-event').sort(_self.sortEventsByStartDate));
            //$(".t-agenda__events-list div").sort((a,b) => $(a).attr("data-start-date") - $(b).attr("data-start-date")).appendTo(".t-agenda__events-list");
          }, 2000);
        }

      }
    });

    // $('.t-agenda__events-list').html($('div[data-barba="container"]:last .o-event').sort(_self.sortEventsByStartDate));

    return false;
  }

  sortEventsByStartDate(a,b) {
    const startA = parseInt($(a).attr('data-start-date'));
    const startB = parseInt($(b).attr('data-start-date'));
    return startA - startB;
  }

}

export { SelectSlider }
