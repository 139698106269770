class TextLoop {
    constructor() {

      this.debug = window.location.search == "?debug" ? true : false;
      // this.debug = true;

    }

    init() {

        let _self = this;

        if(_self.debug) console.log('TextLoop debug?', _self.debug);
        // $('.text-loop').each(function() {
        //   _self.fillWithClones($(this));
          // let _clone = $(this).find('.text-loop__content').clone();
          // let _clone2 = $(this).find('.text-loop__content').clone();
          // let _clone3 = $(this).find('.text-loop__content').clone();
          // $(this).find('.text-loop__container').append(_clone);
          // $(this).find('.text-loop__container').append(_clone2);
          // $(this).find('.text-loop__container').append(_clone3);
        // });
        // $('.text-loop').addClass('animated');

        // animate/pause footer text-loop when scrolled in view/out of view
        let text_loops = $('.text-loop');
        if(text_loops.length) {

          text_loops.each(function(i,text_loop){

            // fill TextLoop elements with content clones
            _self.fillWithClones('.text-loop__container', '.text-loop__content');
            // console.log('footer__social', footer__social);
            _self.TextLoopTrigger = ScrollTrigger.create({
              id: 'text-loop-trigger-'+i,
              trigger: $(text_loop).closest('.o-section'),
              start: "top bottom",
              end: "bottom top",
              onToggle: self => {
                if(_self.debug) console.log("toggled, isActive:", self.isActive);
                if(self.isActive && $(text_loop).find('.text-loop__content').text() != '') $(text_loop).addClass('animated');
                else $(text_loop).removeClass('animated');
              },
              markers: _self.debug,
            });

            // animate/pause footer text-loop when window is visible/hidden
            document.addEventListener('visibilitychange', function (event) {
              if (document.hidden) {
                if(_self.debug) console.log('not visible');
                $(text_loop).removeClass('animated');
              } else {
                if(_self.debug) console.log('is visible');
                if(_self.TextLoopTrigger.isActive && $(text_loop).find('.text-loop__content').text() != '') $(text_loop).addClass('animated');
                else $(text_loop).removeClass('animated');
              }
            });

          });


          $(window).on('resize', _.debounce(_self._resize.bind(_self), 400));
          _self._resize();
        }

    }

    fillWithClones(containerSelector, itemSelector) {

      let _self = this;

      $('.text-loop').each(function(i,textLoop) {
        if(_self.debug) console.log('fillWithClones textLoop #'+i, $(textLoop).attr('class'), $('.text-loop').css('display'));
        if($(textLoop).css('display') != 'none' && $(textLoop).find('.text-loop__content').text() != '') {

          // let _clone = $(this).find('.text-loop__content').clone();
          // let _clone2 = $(this).find('.text-loop__content').clone();
          // let _clone3 = $(this).find('.text-loop__content').clone();
          // $(this).find('.text-loop__container').append(_clone);
          // $(this).find('.text-loop__container').append(_clone2);
          // $(this).find('.text-loop__container').append(_clone3);
          let textLoopWidth = $(textLoop).outerWidth();
          let elements = $(textLoop).find(itemSelector);
          let clonesCount = elements.length;
          if(_self.debug) console.log('fillWithClones textLoop #'+i, textLoop, textLoopWidth);
          let originalWidth = elements.eq(0).width();
          if(originalWidth == 0) {
            if(_self.debug) console.log('fillWithClones originalWidth is ZERO');
            originalWidth = 1;
          }
          if(_self.debug) console.log('fillWithClones originalWidth', originalWidth);
          // let clonesNeeded = Math.ceil((textLoopWidth / originalWidth) + 1);
          let clonesNeeded = Math.ceil((textLoopWidth / originalWidth) + 2);
          if(_self.debug) console.log('fillWithClones clonesNeeded', clonesNeeded);
          if(_self.debug) console.log('fillWithClones current clones count', clonesCount);

          if(clonesCount < clonesNeeded) {
            for (var i = clonesCount; i < clonesNeeded; i++) {
              let _clone = elements.eq(0).clone();
              // _clone.css('left', i * this.decorationOverlap * this.decorationWidth);
              $(textLoop).find(containerSelector).append(_clone);
              clonesCount++;
            }
          } else {
            $(textLoop).find(containerSelector).find(itemSelector).slice(clonesNeeded).remove();
            clonesCount = $(textLoop).find(itemSelector).length;
          }

          //calculate speed depending on element width and container width

          let textLoopDuration = Math.floor((originalWidth*1.5 / textLoopWidth) * 10) + 's';
          if(_self.debug) console.log('fillWithClones textLoopDuration?', textLoopDuration);
          $(textLoop).get(0).style.setProperty("--text-loop-duration", textLoopDuration);


          if(_self.debug) console.log('fillWithClones clonesCount after update', clonesCount);
        } else {
          if(_self.debug) console.log('fillWithClones not necessary as div is not visible #'+i, textLoop);
        }
      });
      //css animation sync
      // window.sync('text_loop');
    }

    _resize() {
      if(this.debug) console.log('fillWithClones this?', this);
      this.fillWithClones('.text-loop__container', '.text-loop__content');
      // if($(window).width() <= 549) {
      //     // $('.text-loop__container').width('auto');
      // } else {
      //   // this.fillWithClones();
      //     // $('.text-loop__container').each(function() {
      //         // let _content = $(this).find('.text-loop__content');
      //         // $(this).width(Math.round(_content.width() * 4));
      //     // });
      // }

    }
}

export { TextLoop };
